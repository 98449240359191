.aui-text {
  &__logo {
    #aui-font.h5();
    #aui-font-family.logo();
    #aui-font-weight.extrabold();
  }

  &__h0 {
    #aui-font.h0();
    #aui-font-family.title();
    #aui-font-weight.extrabold();

    line-height: 3rem;
  }

  &__h1 {
    #aui-font.h1();
    #aui-font-family.title();
    #aui-font-weight.extrabold();
  }

  &__h2 {
    #aui-font.h2();
    #aui-font-family.title();
    #aui-font-weight.extrabold();
  }

  &__h3 {
    #aui-font.h3();
    #aui-font-family.title();
    #aui-font-weight.extrabold();
  }

  &__h4 {
    #aui-font.h4();
    #aui-font-family.title();
    #aui-font-weight.bold();
  }

  &__h5 {
    #aui-font.h5();
    #aui-font-family.title();
    #aui-font-weight.bold();
  }

  &__h6 {
    #aui-font.h6();
    #aui-font-family.title();
    #aui-font-weight.bold();
  }

  &__body-xl {
    #aui-font.body-xl();
    #aui-font-weight.semibold();
  }

  &__body-l {
    #aui-font.body-l();
    #aui-font-weight.medium();
  }

  &__body-m {
    #aui-font.body-m();
    #aui-font-weight.regular();
  }

  &__body-s {
    #aui-font.body-s();
    #aui-font-weight.light();
  }

  &__body-xs {
    #aui-font.body-xs();
    #aui-font-weight.light();
  }
}

.aui-link {
  &__primary {
    #aui-font-weight.bold();

    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: calc(var(--aui-space) / 2);

    width: fit-content;

    color: var(--aui-color-base-interface);
    transition: all var(--aui-transition-speed) var(--aui-transition-smooth);

    &:active {
      color: var(--aui-color-base-interface-hover);
    }

    app-svg._stroke {
      svg {
        fill: none;
        stroke: var(--aui-color-base-interface);
        transition: all var(--aui-transition-speed) var(--aui-transition-smooth);
  
        &:active {
          fill: none;
          stroke: var(--aui-color-base-interface-hover);
        }
      }
    }

    &._disabled {
      opacity: .5;
      cursor: no-drop;

      &:active {
        color: var(--aui-color-base-interface);
      }

      svg, tui-svg {
        &:active {
          fill: var(--aui-color-base-interface);
        }
      }

      app-svg._stroke {
        svg {
          &:active {
            fill: none;
            stroke: var(--aui-color-base-interface);
          }
        }
      }
    }

    &._exit {
      color: var(--aui-color-text-negative);

      &:active {
        color: var(--aui-color-text-negative-hover);
      }

      svg, tui-svg {
        fill: var(--aui-color-text-negative);

        &:active {
          fill: var(--aui-color-text-negative-hover);
        }
      }

      app-svg._stroke {
        svg {
          fill: none;
          stroke: var(--aui-color-text-negative);
          transition: all var(--aui-transition-speed) var(--aui-transition-smooth);
    
          &:active {
            fill: none;
            stroke: var(--aui-color-text-negative-hover);
          }
        }
      }
    }
  }

  &__secondary {
    #aui-font-weight.regular();

    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: calc(var(--aui-space) / 2);

    width: fit-content;

    color: var(--aui-color-base-interface);
    transition: all var(--aui-transition-speed) var(--aui-transition-smooth);

    &:active {
      color: var(--aui-color-base-interface-hover);
    }

    svg, tui-svg {
      fill: var(--aui-color-base-interface);
      transition: all var(--aui-transition-speed) var(--aui-transition-smooth);

      &:active {
        fill: var(--aui-color-base-interface-hover);
      }
    }

    app-svg._stroke {
      svg {
        fill: none;
        stroke: var(--aui-color-base-interface);
        transition: all var(--aui-transition-speed) var(--aui-transition-smooth);
  
        &:active {
          fill: none;
          stroke: var(--aui-color-base-interface-hover);
        }
      }
    }

    &._disabled {
      opacity: .5;
      cursor: no-drop;

      &:active {
        color: var(--aui-color-base-interface);
      }

      svg, tui-svg {
        &:active {
          fill: var(--aui-color-base-interface);
        }
      }

      app-svg._stroke {
        svg {
          &:active {
            fill: none;
            stroke: var(--aui-color-base-interface);
          }
        }
      }
  
    }

    &._exit {
      color: var(--aui-color-text-negative);

      &:active {
        color: var(--aui-color-text-negative-hover);
      }

      svg, tui-svg {
        fill: var(--aui-color-text-negative);

        &:active {
          fill: var(--aui-color-text-negative-hover);
        }
      }
    }
  }
}

.aui-dropdown {
  display: flex;
  flex-direction: column;
  padding: var(--aui-space);

  border: 1px solid var(--aui-color-secondary);

  background-color: var(--aui-color-base-secondary);
  border-radius: var(--aui-space);

  &__item {
    padding: calc(var(--aui-space) / 2) var(--aui-space);

    width: auto;

    transition: all var(--aui-transition-speed) var(--aui-transition-smooth);
    border-radius: var(--aui-space);

    &._active {
      background-color: var(--aui-color-primary-active);
    }

    &:hover {
      background-color: var(--aui-color-primary);
    }

    &:active {
      background-color: var(--aui-color-primary-active);
    }

    &._disabled {
      &:hover {
        background-color: transparent;
      }
  
      &:active {
        background-color: transparent;
      }  
    }
  }
}

.aui-skin {
  display: flex;
  flex-direction: column;
  padding: 0 var(--aui-space);
  gap: calc(var(--aui-space) * 2);

  width: auto;
  height: 100%;
}

@media @aui-media-tablet-min {
  .aui-skin {
    padding: 0 calc(var(--aui-space) * 15);
  }
}

@media @aui-media-dekstop-min {
  .aui-skin {
    padding: 0 calc(var(--aui-space) * 20);
  }
}

@media @aui-media-dekstop-lg-min {
  .aui-skin {
    padding: 0 calc(var(--aui-space) * 30);
  }
}

@media screen and (min-width: 1920px) {
  .aui-skin {
    padding: 0 calc(var(--aui-space) * 40);
  }
}

.aui-button {
  &__primary {
    display: flex;
    align-items: center;
    gap: calc(var(--aui-space) / 2);
    padding: var(--aui-space) calc(var(--aui-space) * 6);

    width: fit-content;

    outline: 1px solid var(--aui-color-secondary);
    background-color: var(--aui-color-primary);
    color: var(--aui-color-base-interface);
    border-radius: var(--aui-space);
    transition: all var(--aui-transition-speed) var(--aui-transition-smooth);

    &:hover {
      background-color: var(--aui-color-primary-hover);
    }

    &:active {
      background-color: var(--aui-color-primary-active);
      color: var(--aui-color-base-interface-hover);
    }

    &:disabled {
      &:hover {
        background-color: var(--aui-color-primary);
      }

      &:active {
        background-color: var(--aui-color-primary);
        color: var(--aui-color-base-interface);
      }
    }

    svg, tui-svg {
      fill: var(--aui-color-base-interface);
      transition: all var(--aui-transition-speed) var(--aui-transition-smooth);

      &:active {
        fill: var(--aui-color-base-interface-hover);
      }
    }

    &._error {
      background-color: var(--aui-color-status-error-bg);
      color: var(--aui-color-status-error-fill);

      &:disabled {
        opacity: 1;

        &:hover {
          background-color: var(--aui-color-status-error-bg);
          color: var(--aui-color-status-error-fill);
        }
  
        &:active {
          background-color: var(--aui-color-status-error-bg);
          color: var(--aui-color-status-error-fill);
        }
      }
    }

    &._success {
      background-color: var(--aui-color-status-success-bg);
      color: var(--aui-color-status-success-fill);

      &:disabled {
        opacity: 1;

        &:hover {
          background-color: var(--aui-color-status-success-bg);
          color: var(--aui-color-status-success-fill);
        }
  
        &:active {
          background-color: var(--aui-color-status-success-bg);
          color: var(--aui-color-status-success-fill);
        }
      }
    }
  }

  &__secondary {
    display: flex;
    align-items: center;
    gap: calc(var(--aui-space) / 2);
    padding: var(--aui-space) calc(var(--aui-space) * 6);

    width: fit-content;

    outline: 1px solid var(--aui-color-secondary);
    background-color: var(--aui-color-base-secondary);
    color: var(--aui-color-base-interface);
    border-radius: var(--aui-space);
    transition: all var(--aui-transition-speed) var(--aui-transition-smooth);

    &:active {
      background-color: var(--aui-color-base-primary);
      color: var(--aui-color-base-interface-hover);
    }
    &._active {
      background-color: var(--aui-color-base-primary);
      color: var(--aui-color-base-interface-hover);
    }

    &:disabled {
      &:active {
        background-color: var(--aui-color-base-secondary);
        color: var(--aui-color-base-interface);
      }
    }

    svg, tui-svg {
      fill: var(--aui-color-base-interface);
      transition: all var(--aui-transition-speed) var(--aui-transition-smooth);

      &:active {
        fill: var(--aui-color-base-interface-hover);
      }
    }
  }

  &__helper {
    display: flex;
    align-items: center;
    gap: calc(var(--aui-space) / 2);
    padding: var(--aui-space) calc(var(--aui-space) * 6);

    width: fit-content;

    outline: 1px solid var(--aui-color-base-interface);
    background-color: transparent;
    color: var(--aui-color-base-interface);
    border-radius: var(--aui-space);
    transition: all var(--aui-transition-speed) var(--aui-transition-smooth);

    &:active {
      outline: 1px solid var(--aui-color-base-interface-hover);
      background-color: var(--aui-color-secondary);
      color: var(--aui-color-base-interface-hover);
    }
    &._active {
      outline: 1px solid var(--aui-color-base-interface-hover);
      background-color: var(--aui-color-secondary);
      color: var(--aui-color-base-interface-hover); 
    }

    &:disabled {
      &:active {
        outline: 1px solid var(--aui-color-base-interface);
        background-color: transparent;
        color: var(--aui-color-base-interface);
      }
    }

    svg, tui-svg {
      fill: var(--aui-color-base-interface);
      transition: all var(--aui-transition-speed) var(--aui-transition-smooth);

      &:active {
        fill: var(--aui-color-base-interface-hover);
      }
    }
  }
}

.aui-input {
  [tuiWrapper][data-appearance='textfield'] {
    background-color: var(--aui-color-base-secondary);
  }

  [tuiWrapper][data-appearance='textfield'][data-mode='onDark'] {
    background-color: var(--aui-color-base-secondary);
  }

  &:hover {
    [tuiWrapper][data-appearance='textfield'][data-mode='onDark'] {
      background-color: var(--aui-color-base-secondary);
    }
  }

  [tuiWrapper][data-appearance='textfield'][data-mode='onDark']._focused {
    background-color: var(--aui-color-base-primary);
  }

  input[_nghost-ng-c3600615812] {
    #aui-font.body-m();
    #aui-font-weight.regular();
  }

  &__transparent {
    input[_nghost-ng-c3600615812] {
      #aui-font.body-m();
      #aui-font-weight.regular();
    }

    [tuiWrapper][data-appearance='textfield'] {
      background-color: var(--aui-color-base-secondary);
    }

    [tuiWrapper][data-appearance='textfield'][data-mode='onDark'] {
      background-color: transparent !important;
      
      &::after {
        color: transparent;
      }

      &:hover {
        background-color: transparent;
      
        &::after {
          color: transparent;
        }
      }

      &:active {
        background-color: transparent;
      
        &::after {
          color: transparent;
        }
      }
    }

    [data-size=s][_nghost-ng-c2724824487] .t-content[_ngcontent-ng-c2724824487] {
      padding: 0 calc(var(--aui-space) / 2) 0 0;
    }
  }
}

.aui-margin {
  &__top {
    > * {
      margin-top: calc(var(--aui-space) * 4);
    }
  }

  &__right {
    > * {
      margin-right: calc(var(--aui-space) * 4);
    }
  }
}

.aui-carousel {
  &__buttons {
    tui-carousel {
      height: inherit;

      div.t-scroller {
        height: inherit;
      }

      div.t-items {
        height: -webkit-fill-available;
        height: -moz-available;
      }
    }
  }
}

.item {
  &__discount {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--aui-space);
    padding: var(--aui-space);

    border-radius: var(--aui-space);

    &._first-level {
      background-color: var(--aui-color-status-neutral-bg);
      color: var(--aui-color-status-neutral-fill);
    }

    &._second-level {
      background-color: var(--aui-color-status-warning-bg);
      color: var(--aui-color-status-warning-fill);
    }

    &._third-level {
      background-color: var(--aui-color-status-error-bg);
      color: var(--aui-color-status-error-fill);
    }
  }
}

.buttons {
  &__counter {
    display: flex;
    align-items: center;
    gap: calc(var(--aui-space) / 2);

    button {
      padding: 0;

      outline: none;
    }
  }

  &__item {
    &:first-child {
      #aui-font-weight.bold();
      #aui-font-family.title();

      padding: calc(var(--aui-space) / 2) calc(var(--aui-space) * 2);
    }

    &:last-child {
      padding: 0 calc(var(--aui-space) / 2);

      height: 26px;
    }
  }

  &__go-to-cart {
    input {
      cursor: pointer;
    }
  }
}

.aui-loader {
  opacity: 0;

  &__app-item {
    background-color: var(--aui-color-base-secondary);

    p {
      opacity: 0;
    }

    hr {
      opacity: 0;
    }

    button {
      opacity: 0;
    }
  }
}

.aui-table {
  width: 100%;

  th {
    background-color: var(--aui-color-base-secondary);
    color: var(--aui-color-text-primary);
    border-color: var(--aui-color-secondary);
    vertical-align: middle;
  }

  td {
    background-color: var(--aui-color-base-secondary);
    color: var(--aui-color-text-secondary);
    border-color: var(--aui-color-secondary);
    vertical-align: middle;
  }
}