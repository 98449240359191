body[data-tui-theme].r-tui-body {
  background-color: var(--aui-color-base-primary);
}
tui-dropdown[_nghost-ng-c2374896908] {
  border: 1px solid var(--aui-color-secondary);
  background: none;
}
app-root {
  display: block;
  height: 100%;
}
.t-root-content {
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  width: 100%;
}
fieldset.t-item[_ngcontent-ng-c121344037] {
  align-items: center;
  padding: 0;
}
div[tuiWrapper][data-appearance='textfield'][data-state='readonly'][data-state='readonly']:after {
  color: var(--aui-color-secondary);
}
div.t-thumb[_ngcontent-ng-c2790313665] {
  color: white;
}
tui-select-option .t-content {
  cursor: pointer;
}
tui-dropdown[_nghost-ng-c2374896908] {
  background-color: var(--aui-color-base-secondary);
}
tui-dropdown[data-mode=onDark][_nghost-ng-c2374896908] {
  border: 1px solid var(--aui-color-secondary);
  background-color: var(--aui-color-base-secondary);
}
tui-dropdown[data-mode=onDark][_nghost-ng-c2374896908] button:hover {
  background-color: var(--aui-color-base-primary);
}
[_nghost-ng-c819886985] div.t-wrapper[_ngcontent-ng-c819886985]:after {
  border-color: var(--aui-color-secondary);
}
[data-mode=onDark][_nghost-ng-c819886985] div.t-wrapper[_ngcontent-ng-c819886985]:after {
  border-color: var(--aui-color-secondary);
}
button.t-header[_ngcontent-ng-c819886985] .t-title {
  cursor: pointer;
}
[_nghost-ng-c819886985] button.t-header[_ngcontent-ng-c819886985] {
  border-color: var(--aui-color-secondary);
  background-color: var(--aui-color-base-secondary);
}
[data-mode=onDark][_nghost-ng-c819886985] button.t-header[_ngcontent-ng-c819886985] {
  border-color: var(--aui-color-secondary);
  background-color: var(--aui-color-base-secondary);
}
.t-wrapper[_ngcontent-ng-c819886985]:hover:not([data-mode]) > button.t-header_hoverable[_ngcontent-ng-c819886985] {
  background-color: var(--aui-color-base-primary);
}
input.t-input[_ngcontent-ng-c2294440466] {
  color: var(--aui-color-text-primary);
}
:root {
  --tui-primary: #f4a261;
  --tui-primary-hover: #e9c46a;
  --tui-primary-active: #e76f51;
}
span.t-content[_ngcontent-ng-c2966975625] {
  cursor: pointer;
}
div[tuiWrapper][data-appearance='secondary'] {
  background-color: var(--aui-color-base-secondary);
}
div[tuiWrapper][data-appearance='secondary'][data-mode='onDark'] {
  background-color: var(--aui-color-base-secondary);
}
div[tuiWrapper][data-appearance='secondary'][data-mode='onDark']:hover:not(._no-hover) {
  background-color: var(--aui-color-base-primary);
}
div[tuiWrapper][data-appearance='secondary'][data-mode='onDark']:active:not(._no-active) {
  background-color: var(--aui-color-primary);
}
[data-size=m][tuiIconButton][_nghost-ng-c3926154569] .t-svg[_ngcontent-ng-c3944244699] {
  cursor: pointer;
}
div.t-content[_ngcontent-ng-c1591437670] {
  width: auto;
  background: transparent;
}
div.t-buttons[_ngcontent-ng-c1591437670] {
  display: none;
}
tui-dialog[_nghost-ng-c1591437670]::after {
  box-shadow: none;
}
tui-dialog[data-size=l][_nghost-ng-c1591437670] {
  width: 70rem;
}
tui-root._mobile tui-dialog[data-size][_nghost-ng-c1591437670] {
  margin: auto 0;
}
[_nghost-ng-c1591437670]:not([data-size="fullscreen"]):not([data-size="page"]) button.t-close[_ngcontent-ng-c1591437670] {
  background-color: var(--aui-color-base-secondary);
  cursor: pointer;
}
[_nghost-ng-c1591437670]:not([data-size="fullscreen"]):not([data-size="page"]) button.t-close[_ngcontent-ng-c1591437670]:active {
  background-color: var(--aui-color-base-primary);
}
[_nghost-ng-c1591437670]:not([data-size="fullscreen"]):not([data-size="page"]) button.t-close[_ngcontent-ng-c1591437670] svg.t-svg[_ngcontent-ng-c3944244699] {
  cursor: pointer;
}
[_nghost-ng-c1591437670]:not([data-size="fullscreen"]):not([data-size="page"]) button.t-close[_ngcontent-ng-c1591437670]:hover {
  background-color: var(--aui-color-base-primary);
}
tui-root._mobile [_nghost-ng-c1591437670] button.t-close[_ngcontent-ng-c1591437670] {
  color: var(--aui-color-text-primary);
}
tui-data-list[data-list-size=s] > div.t-empty {
  color: var(--aui-color-text-helper);
}
[data-size=m][_nghost-ng-c219241606] button.t-close[_ngcontent-ng-c219241606] svg {
  cursor: pointer;
}
[tuiWrapper][data-mode=onDark][_ngcontent-ng-c2294440466] label.t-placeholder[_ngcontent-ng-c2294440466] {
  display: flex;
  align-items: center;
  gap: var(--aui-space);
}
[tuiWrapper][data-mode=onDark][_ngcontent-ng-c2294440466] label.t-placeholder[_ngcontent-ng-c2294440466] span {
  font-size: 11px;
  color: var(--aui-color-text-secondary);
  line-height: 1rem;
}
tui-badge[data-status=primary][_nghost-ng-c783107866] {
  background-color: var(--aui-color-primary);
}
tui-badge[data-status=default][_nghost-ng-c783107866] {
  border: 1px solid var(--aui-color-secondary);
  background-color: var(--aui-color-status-info-bg);
}
tui-hint {
  background-color: var(--aui-color-base-secondary) !important;
  color: var(--aui-color-text-primary) !important;
}
tui-alert {
  margin: 6rem 3rem 0 auto !important;
}
tui-badge[data-status=info][_nghost-ng-c783107866] {
  color: var(--aui-color-text-primary);
}
tui-loader fieldset.t-content[_ngcontent-ng-c717223331] {
  display: flex;
  flex-direction: column;
  gap: var(--aui-space);
  margin-top: calc(var(--aui-space) * 2);
}
tui-badge[data-status=success][_nghost-ng-c783107866] {
  color: var(--aui-color-text-primary);
}
tui-badge[data-status=error][_nghost-ng-c783107866] {
  color: var(--aui-color-text-primary);
}
.t-content[_ngcontent-ng-c3926154569] {
  color: var(--aui-color-text-primary);
}
div.t-row_weekday[_ngcontent-ng-c3738844098] {
  color: var(--aui-color-text-secondary);
}
span.t-content[_ngcontent-ng-c2877806562] {
  color: var(--aui-color-text-primary);
}
div.t-item[_ngcontent-ng-c3959805629] {
  color: var(--aui-color-text-primary);
}
tui-radio-block [tuiWrapper][data-appearance='secondary'][data-mode='onDark'] {
  background-color: var(--aui-color-base-secondary);
}
tui-radio-block [tuiWrapper][data-appearance='primary'] {
  background-color: var(--aui-color-primary);
}
tui-radio-block [tuiWrapper][data-appearance='primary']:hover {
  background-color: var(--aui-color-primary) !important;
}
div.t-primitive-value[_ngcontent-ng-c317350843] {
  color: var(--aui-color-text-primary);
}
span.t-content[_ngcontent-ng-c2966975625] {
  color: var(--aui-color-base-interface);
}
div[tuiWrapper][data-appearance='textfield'][data-appearance='textfield']:after {
  color: var(--aui-color-secondary);
}
tui-arrow svg {
  color: var(--aui-color-base-interface);
}
tui-data-list[data-list-size="s"] [_nghost-ng-c1028746238] .t-checkmark[_ngcontent-ng-c1028746238] svg {
  color: var(--aui-color-base-interface);
}
div.t-empty {
  color: var(--aui-color-text-helper);
  font-size: 12px;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('./assets/fonts/GraphikLCG/GraphikLCG-MediumItalic.eot');
  src: local('Graphik LCG Medium Italic'), local('GraphikLCG-MediumItalic'), url('./assets/fonts/GraphikLCG/GraphikLCG-MediumItalic.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/GraphikLCG/GraphikLCG-MediumItalic.woff2') format('woff2'), url('./assets/fonts/GraphikLCG/GraphikLCG-MediumItalic.woff') format('woff'), url('./assets/fonts/GraphikLCG/GraphikLCG-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('./assets/fonts/GraphikLCG/GraphikLCG-SuperItalic.eot');
  src: local('Graphik LCG Super Italic'), local('GraphikLCG-SuperItalic'), url('./assets/fonts/GraphikLCG/GraphikLCG-SuperItalic.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/GraphikLCG/GraphikLCG-SuperItalic.woff2') format('woff2'), url('./assets/fonts/GraphikLCG/GraphikLCG-SuperItalic.woff') format('woff'), url('./assets/fonts/GraphikLCG/GraphikLCG-SuperItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('./assets/fonts/GraphikLCG/GraphikLCG-SemiboldItalic.eot');
  src: local('Graphik LCG Semibold Italic'), local('GraphikLCG-SemiboldItalic'), url('./assets/fonts/GraphikLCG/GraphikLCG-SemiboldItalic.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/GraphikLCG/GraphikLCG-SemiboldItalic.woff2') format('woff2'), url('./assets/fonts/GraphikLCG/GraphikLCG-SemiboldItalic.woff') format('woff'), url('./assets/fonts/GraphikLCG/GraphikLCG-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('./assets/fonts/GraphikLCG/GraphikLCG-Black.eot');
  src: local('Graphik LCG Black'), local('GraphikLCG-Black'), url('./assets/fonts/GraphikLCG/GraphikLCG-Black.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/GraphikLCG/GraphikLCG-Black.woff2') format('woff2'), url('./assets/fonts/GraphikLCG/GraphikLCG-Black.woff') format('woff'), url('./assets/fonts/GraphikLCG/GraphikLCG-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('./assets/fonts/GraphikLCG/GraphikLCG-Super.eot');
  src: local('Graphik LCG Super'), local('GraphikLCG-Super'), url('./assets/fonts/GraphikLCG/GraphikLCG-Super.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/GraphikLCG/GraphikLCG-Super.woff2') format('woff2'), url('./assets/fonts/GraphikLCG/GraphikLCG-Super.woff') format('woff'), url('./assets/fonts/GraphikLCG/GraphikLCG-Super.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('./assets/fonts/GraphikLCG/GraphikLCG-Thin.eot');
  src: local('Graphik LCG Thin'), local('GraphikLCG-Thin'), url('./assets/fonts/GraphikLCG/GraphikLCG-Thin.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/GraphikLCG/GraphikLCG-Thin.woff2') format('woff2'), url('./assets/fonts/GraphikLCG/GraphikLCG-Thin.woff') format('woff'), url('./assets/fonts/GraphikLCG/GraphikLCG-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('./assets/fonts/GraphikLCG/GraphikLCG-ExtralightItalic.eot');
  src: local('Graphik LCG Extralight Italic'), local('GraphikLCG-ExtralightItalic'), url('./assets/fonts/GraphikLCG/GraphikLCG-ExtralightItalic.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/GraphikLCG/GraphikLCG-ExtralightItalic.woff2') format('woff2'), url('./assets/fonts/GraphikLCG/GraphikLCG-ExtralightItalic.woff') format('woff'), url('./assets/fonts/GraphikLCG/GraphikLCG-ExtralightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('./assets/fonts/GraphikLCG/GraphikLCG-Regular.eot');
  src: local('Graphik LCG Regular'), local('GraphikLCG-Regular'), url('./assets/fonts/GraphikLCG/GraphikLCG-Regular.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/GraphikLCG/GraphikLCG-Regular.woff2') format('woff2'), url('./assets/fonts/GraphikLCG/GraphikLCG-Regular.woff') format('woff'), url('./assets/fonts/GraphikLCG/GraphikLCG-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('./assets/fonts/GraphikLCG/GraphikLCG-BoldItalic.eot');
  src: local('Graphik LCG Bold Italic'), local('GraphikLCG-BoldItalic'), url('./assets/fonts/GraphikLCG/GraphikLCG-BoldItalic.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/GraphikLCG/GraphikLCG-BoldItalic.woff2') format('woff2'), url('./assets/fonts/GraphikLCG/GraphikLCG-BoldItalic.woff') format('woff'), url('./assets/fonts/GraphikLCG/GraphikLCG-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('./assets/fonts/GraphikLCG/GraphikLCG-LightItalic.eot');
  src: local('Graphik LCG Light Italic'), local('GraphikLCG-LightItalic'), url('./assets/fonts/GraphikLCG/GraphikLCG-LightItalic.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/GraphikLCG/GraphikLCG-LightItalic.woff2') format('woff2'), url('./assets/fonts/GraphikLCG/GraphikLCG-LightItalic.woff') format('woff'), url('./assets/fonts/GraphikLCG/GraphikLCG-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('./assets/fonts/GraphikLCG/GraphikLCG-BlackItalic.eot');
  src: local('Graphik LCG Black Italic'), local('GraphikLCG-BlackItalic'), url('./assets/fonts/GraphikLCG/GraphikLCG-BlackItalic.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/GraphikLCG/GraphikLCG-BlackItalic.woff2') format('woff2'), url('./assets/fonts/GraphikLCG/GraphikLCG-BlackItalic.woff') format('woff'), url('./assets/fonts/GraphikLCG/GraphikLCG-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('./assets/fonts/GraphikLCG/GraphikLCG-Medium.eot');
  src: local('Graphik LCG Medium'), local('GraphikLCG-Medium'), url('./assets/fonts/GraphikLCG/GraphikLCG-Medium.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/GraphikLCG/GraphikLCG-Medium.woff2') format('woff2'), url('./assets/fonts/GraphikLCG/GraphikLCG-Medium.woff') format('woff'), url('./assets/fonts/GraphikLCG/GraphikLCG-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('./assets/fonts/GraphikLCG/GraphikLCG-Light.eot');
  src: local('Graphik LCG Light'), local('GraphikLCG-Light'), url('./assets/fonts/GraphikLCG/GraphikLCG-Light.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/GraphikLCG/GraphikLCG-Light.woff2') format('woff2'), url('./assets/fonts/GraphikLCG/GraphikLCG-Light.woff') format('woff'), url('./assets/fonts/GraphikLCG/GraphikLCG-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('./assets/fonts/GraphikLCG/GraphikLCG-Semibold.eot');
  src: local('Graphik LCG Semibold'), local('GraphikLCG-Semibold'), url('./assets/fonts/GraphikLCG/GraphikLCG-Semibold.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/GraphikLCG/GraphikLCG-Semibold.woff2') format('woff2'), url('./assets/fonts/GraphikLCG/GraphikLCG-Semibold.woff') format('woff'), url('./assets/fonts/GraphikLCG/GraphikLCG-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('./assets/fonts/GraphikLCG/GraphikLCG-Bold.eot');
  src: local('Graphik LCG Bold'), local('GraphikLCG-Bold'), url('./assets/fonts/GraphikLCG/GraphikLCG-Bold.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/GraphikLCG/GraphikLCG-Bold.woff2') format('woff2'), url('./assets/fonts/GraphikLCG/GraphikLCG-Bold.woff') format('woff'), url('./assets/fonts/GraphikLCG/GraphikLCG-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('./assets/fonts/GraphikLCG/GraphikLCG-Extralight.eot');
  src: local('Graphik LCG Extralight'), local('GraphikLCG-Extralight'), url('./assets/fonts/GraphikLCG/GraphikLCG-Extralight.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/GraphikLCG/GraphikLCG-Extralight.woff2') format('woff2'), url('./assets/fonts/GraphikLCG/GraphikLCG-Extralight.woff') format('woff'), url('./assets/fonts/GraphikLCG/GraphikLCG-Extralight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('./assets/fonts/GraphikLCG/GraphikLCG-ThinItalic.eot');
  src: local('Graphik LCG Thin Italic'), local('GraphikLCG-ThinItalic'), url('./assets/fonts/GraphikLCG/GraphikLCG-ThinItalic.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/GraphikLCG/GraphikLCG-ThinItalic.woff2') format('woff2'), url('./assets/fonts/GraphikLCG/GraphikLCG-ThinItalic.woff') format('woff'), url('./assets/fonts/GraphikLCG/GraphikLCG-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Graphik LCG';
  src: url('./assets/fonts/GraphikLCG/GraphikLCG-RegularItalic.eot');
  src: local('Graphik LCG Regular Italic'), local('GraphikLCG-RegularItalic'), url('./assets/fonts/GraphikLCG/GraphikLCG-RegularItalic.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/GraphikLCG/GraphikLCG-RegularItalic.woff2') format('woff2'), url('./assets/fonts/GraphikLCG/GraphikLCG-RegularItalic.woff') format('woff'), url('./assets/fonts/GraphikLCG/GraphikLCG-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Druk Wide Cyr';
  src: url('./assets/fonts/DrukCyrillicCollection/DrukWideCyrBold.otf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Druk Wide Cyr';
  src: url('./assets/fonts/DrukCyrillicCollection/DrukWideCyrBoldItalic.otf');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Druk Wide Cyr';
  src: url('./assets/fonts/DrukCyrillicCollection/DrukWideCyrHeavy.otf');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Druk Wide Cyr';
  src: url('./assets/fonts/DrukCyrillicCollection/DrukWideCyrHeavyItalic.otf');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Druk Wide Cyr';
  src: url('./assets/fonts/DrukCyrillicCollection/DrukWideCyrMedium.otf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Druk Wide Cyr';
  src: url('./assets/fonts/DrukCyrillicCollection/DrukWideCyrMediumItalic.otf');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Druk Wide Cyr';
  src: url('./assets/fonts/DrukCyrillicCollection/DrukWideCyrSuper.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Druk Wide Cyr';
  src: url('./assets/fonts/DrukCyrillicCollection/DrukWideCyrSuperItalic.otf');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-BlkSlanted.eot');
  src: local('Halvar Breitschrift Black Slanted'), local('HalvarBreit-BlkSlanted'), url('./assets/fonts/HalvarBreit/HalvarBreit-BlkSlanted.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-BlkSlanted.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-BlkSlanted.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-BlkSlanted.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-Blk.eot');
  src: local('Halvar Breitschrift Black'), local('HalvarBreit-Blk'), url('./assets/fonts/HalvarBreit/HalvarBreit-Blk.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-Blk.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-Blk.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-Blk.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-BlkSuSlanted.eot');
  src: local('Halvar Breitschrift Black SuperSlanted'), local('HalvarBreit-BlkSuSlanted'), url('./assets/fonts/HalvarBreit/HalvarBreit-BlkSuSlanted.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-BlkSuSlanted.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-BlkSuSlanted.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-BlkSuSlanted.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-Lt.eot');
  src: local('Halvar Breitschrift Light'), local('HalvarBreit-Lt'), url('./assets/fonts/HalvarBreit/HalvarBreit-Lt.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-Lt.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-Lt.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-Lt.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-HlSuSlanted.eot');
  src: local('Halvar Breitschrift Hairline SuperSlanted'), local('HalvarBreit-HlSuSlanted'), url('./assets/fonts/HalvarBreit/HalvarBreit-HlSuSlanted.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-HlSuSlanted.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-HlSuSlanted.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-HlSuSlanted.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-Md.eot');
  src: local('Halvar Breitschrift Medium'), local('HalvarBreit-Md'), url('./assets/fonts/HalvarBreit/HalvarBreit-Md.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-Md.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-Md.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-Md.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-XThSuSlanted.eot');
  src: local('Halvar Breitschrift ExtraThin SuperSlanted'), local('HalvarBreit-XThSuSlanted'), url('./assets/fonts/HalvarBreit/HalvarBreit-XThSuSlanted.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-XThSuSlanted.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-XThSuSlanted.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-XThSuSlanted.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-Bd.eot');
  src: local('Halvar Breitschrift Bold'), local('HalvarBreit-Bd'), url('./assets/fonts/HalvarBreit/HalvarBreit-Bd.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-Bd.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-Bd.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-Bd.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-MdSuSlanted.eot');
  src: local('Halvar Breitschrift Medium SuperSlanted'), local('HalvarBreit-MdSuSlanted'), url('./assets/fonts/HalvarBreit/HalvarBreit-MdSuSlanted.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-MdSuSlanted.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-MdSuSlanted.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-MdSuSlanted.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-HlSlanted.eot');
  src: local('Halvar Breitschrift Hairline Slanted'), local('HalvarBreit-HlSlanted'), url('./assets/fonts/HalvarBreit/HalvarBreit-HlSlanted.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-HlSlanted.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-HlSlanted.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-HlSlanted.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-RgSlanted.eot');
  src: local('Halvar Breitschrift Regular Slanted'), local('HalvarBreit-RgSlanted'), url('./assets/fonts/HalvarBreit/HalvarBreit-RgSlanted.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-RgSlanted.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-RgSlanted.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-RgSlanted.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-XBd.eot');
  src: local('Halvar Breitschrift ExtraBold'), local('HalvarBreit-XBd'), url('./assets/fonts/HalvarBreit/HalvarBreit-XBd.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-XBd.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-XBd.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-XBd.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-XThSlanted.eot');
  src: local('Halvar Breitschrift ExtraThin Slanted'), local('HalvarBreit-XThSlanted'), url('./assets/fonts/HalvarBreit/HalvarBreit-XThSlanted.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-XThSlanted.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-XThSlanted.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-XThSlanted.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-ThSuSlanted.eot');
  src: local('Halvar Breitschrift Thin SuperSlanted'), local('HalvarBreit-ThSuSlanted'), url('./assets/fonts/HalvarBreit/HalvarBreit-ThSuSlanted.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-ThSuSlanted.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-ThSuSlanted.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-ThSuSlanted.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-XBdSuSlanted.eot');
  src: local('Halvar Breitschrift ExtraBold SuperSlanted'), local('HalvarBreit-XBdSuSlanted'), url('./assets/fonts/HalvarBreit/HalvarBreit-XBdSuSlanted.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-XBdSuSlanted.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-XBdSuSlanted.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-XBdSuSlanted.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-RgSuSlanted.eot');
  src: local('Halvar Breitschrift Regular SuperSlanted'), local('HalvarBreit-RgSuSlanted'), url('./assets/fonts/HalvarBreit/HalvarBreit-RgSuSlanted.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-RgSuSlanted.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-RgSuSlanted.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-RgSuSlanted.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-XBdSlanted.eot');
  src: local('Halvar Breitschrift ExtraBold Slanted'), local('HalvarBreit-XBdSlanted'), url('./assets/fonts/HalvarBreit/HalvarBreit-XBdSlanted.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-XBdSlanted.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-XBdSlanted.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-XBdSlanted.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-LtSuSlanted.eot');
  src: local('Halvar Breitschrift Light SuperSlanted'), local('HalvarBreit-LtSuSlanted'), url('./assets/fonts/HalvarBreit/HalvarBreit-LtSuSlanted.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-LtSuSlanted.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-LtSuSlanted.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-LtSuSlanted.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-Th.eot');
  src: local('Halvar Breitschrift Thin'), local('HalvarBreit-Th'), url('./assets/fonts/HalvarBreit/HalvarBreit-Th.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-Th.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-Th.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-Th.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-XTh.eot');
  src: local('Halvar Breitschrift ExtraThin'), local('HalvarBreit-XTh'), url('./assets/fonts/HalvarBreit/HalvarBreit-XTh.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-XTh.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-XTh.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-XTh.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-BdSuSlanted.eot');
  src: local('Halvar Breitschrift Bold SuperSlanted'), local('HalvarBreit-BdSuSlanted'), url('./assets/fonts/HalvarBreit/HalvarBreit-BdSuSlanted.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-BdSuSlanted.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-BdSuSlanted.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-BdSuSlanted.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-ThSlanted.eot');
  src: local('Halvar Breitschrift Thin Slanted'), local('HalvarBreit-ThSlanted'), url('./assets/fonts/HalvarBreit/HalvarBreit-ThSlanted.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-ThSlanted.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-ThSlanted.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-ThSlanted.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-LtSlanted.eot');
  src: local('Halvar Breitschrift Light Slanted'), local('HalvarBreit-LtSlanted'), url('./assets/fonts/HalvarBreit/HalvarBreit-LtSlanted.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-LtSlanted.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-LtSlanted.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-LtSlanted.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-Hl.eot');
  src: local('Halvar Breitschrift Hairline'), local('HalvarBreit-Hl'), url('./assets/fonts/HalvarBreit/HalvarBreit-Hl.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-Hl.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-Hl.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-Hl.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-MdSlanted.eot');
  src: local('Halvar Breitschrift Medium Slanted'), local('HalvarBreit-MdSlanted'), url('./assets/fonts/HalvarBreit/HalvarBreit-MdSlanted.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-MdSlanted.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-MdSlanted.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-MdSlanted.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-BdSlanted.eot');
  src: local('Halvar Breitschrift Bold Slanted'), local('HalvarBreit-BdSlanted'), url('./assets/fonts/HalvarBreit/HalvarBreit-BdSlanted.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-BdSlanted.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-BdSlanted.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-BdSlanted.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-Hl_1.eot');
  src: local('Halvar Breitschrift Hairline'), local('HalvarBreit-Hl'), url('./assets/fonts/HalvarBreit/HalvarBreit-Hl_1.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-Hl_1.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-Hl_1.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-Hl_1.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Halvar Breit';
  src: url('./assets/fonts/HalvarBreit/HalvarBreit-Rg.eot');
  src: local('Halvar Breitschrift Regular'), local('HalvarBreit-Rg'), url('./assets/fonts/HalvarBreit/HalvarBreit-Rg.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/HalvarBreit/HalvarBreit-Rg.woff2') format('woff2'), url('./assets/fonts/HalvarBreit/HalvarBreit-Rg.woff') format('woff'), url('./assets/fonts/HalvarBreit/HalvarBreit-Rg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
:root {
  --aui-space: 10px;
  --aui-transition-speed: 0.25s;
  --aui-transition-smooth: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  --aui-color-primary: #D03100;
  --aui-color-primary-hover: #9e0011;
  --aui-color-primary-active: #e45e01;
  --aui-color-primary-light: #f4a261;
  --aui-color-primary-light-hover: #e9c46a;
  --aui-color-primary-light-active: #e76f51;
  --aui-color-secondary: #FFFFFF20;
  --aui-color-secondary-hover: #FFFFFF30;
  --aui-color-secondary-active: #FFFFFF40;
  --aui-color-selection: #161E2210;
  --aui-color-text-primary: #FCFCFD;
  --aui-color-text-secondary: #F3F5F790;
  --aui-color-text-helper: #E0E1E070;
  --aui-color-text-link: #A3CEF1;
  --aui-color-text-link-hover: #70B3EA;
  --aui-color-text-positive: #CDF1A2;
  --aui-color-text-positive-hover: #A5E657;
  --aui-color-text-negative: #F8CFC9;
  --aui-color-text-negative-hover: #ED8474;
  --aui-color-text-primary-night: #0E0E16;
  --aui-color-text-secondary-night: #0E111590;
  --aui-color-text-helper-night: #10101070;
  --aui-color-text-link-night: #1A6CAF;
  --aui-color-text-link-night-hover: #175D97;
  --aui-color-text-positive-night: #6CAF1A;
  --aui-color-text-positive-night-hover: #558A15;
  --aui-color-text-negative-night: #C7311A;
  --aui-color-text-negative-night-hover: #9C2614;
  --aui-color-status-error-fill: #F8CFC9;
  --aui-color-status-error-bg: #DC565850;
  --aui-color-status-error-bg-hover: #DC565870;
  --aui-color-status-success-fill: #C9F8EF;
  --aui-color-status-success-bg: #1AC7A750;
  --aui-color-status-success-bg-hover: #1AC7A770;
  --aui-color-status-warning-fill: #F8EFC9;
  --aui-color-status-warning-bg: #A67D0050;
  --aui-color-status-warning-bg-hover: #A67D0070;
  --aui-color-status-info-fill: #C9E1F8;
  --aui-color-status-info-bg: #45607A50;
  --aui-color-status-info-bg-hover: #45607A70;
  --aui-color-status-neutral-fill: #CBCCCF;
  --aui-color-status-neutral-bg: #999CA050;
  --aui-color-status-neutral-bg-hover: #999CA070;
  --aui-color-status-error-fill-night: #DC5658;
  --aui-color-status-error-bg-night: #F8CFC950;
  --aui-color-status-error-bg-night-hover: #F8CFC970;
  --aui-color-status-success-fill-night: #1AC7A7;
  --aui-color-status-success-bg-night: #C9F8EF50;
  --aui-color-status-success-bg-night-hover: #C9F8EF70;
  --aui-color-status-warning-fill-night: #FFC20B;
  --aui-color-status-warning-bg-night: #F8EFC950;
  --aui-color-status-warning-bg-night-hover: #F8EFC970;
  --aui-color-status-info-fill-night: #45607A;
  --aui-color-status-info-bg-night: #C9E1F850;
  --aui-color-status-info-bg-night-hover: #C9E1F870;
  --aui-color-status-neutral-fill-night: #999CA0;
  --aui-color-status-neutral-bg-night: #CBCCCF50;
  --aui-color-status-neutral-bg-night-hover: #CBCCCF70;
  --aui-color-base-primary: #0D0D0D;
  --aui-color-base-secondary: #141414;
  --aui-color-base-helper: #868A88;
  --aui-color-base-helper-hover: #8F979B;
  --aui-color-base-interface: #C7CBCD;
  --aui-color-base-interface-hover: #E5EBEA;
  --aui-color-base-primary-invert: #F2F4F3;
  --aui-color-base-secondary-invert: #DCE3DB;
  --aui-color-base-helper-invert: #F8F9F9;
  --aui-color-base-interface-invert: #FFFFFF;
}
.aui-text__logo {
  font-size: 24px;
  color: var(--aui-color-text-primary);
  line-height: 2rem;
  font-family: 'Druk Wide Cyr';
  font-weight: 800;
}
.aui-text__h0 {
  font-size: 64px;
  color: var(--aui-color-text-primary);
  line-height: 2rem;
  font-family: 'Halvar Breit';
  font-weight: 800;
  line-height: 3rem;
}
.aui-text__h1 {
  font-size: 50px;
  color: var(--aui-color-text-primary);
  line-height: 2rem;
  font-family: 'Halvar Breit';
  font-weight: 800;
}
.aui-text__h2 {
  font-size: 44px;
  color: var(--aui-color-text-primary);
  line-height: 2rem;
  font-family: 'Halvar Breit';
  font-weight: 800;
}
.aui-text__h3 {
  font-size: 36px;
  color: var(--aui-color-text-primary);
  line-height: 2rem;
  font-family: 'Halvar Breit';
  font-weight: 800;
}
.aui-text__h4 {
  font-size: 28px;
  color: var(--aui-color-text-primary);
  line-height: 2rem;
  font-family: 'Halvar Breit';
  font-weight: 700;
}
.aui-text__h5 {
  font-size: 24px;
  color: var(--aui-color-text-primary);
  line-height: 2rem;
  font-family: 'Halvar Breit';
  font-weight: 700;
}
.aui-text__h6 {
  font-size: 20px;
  color: var(--aui-color-text-primary);
  line-height: 2rem;
  font-family: 'Halvar Breit';
  font-weight: 700;
}
.aui-text__body-xl {
  font-size: 19px;
  color: var(--aui-color-text-primary);
  line-height: 1rem;
  font-weight: 600;
}
.aui-text__body-l {
  font-size: 17px;
  color: var(--aui-color-text-secondary);
  line-height: 1rem;
  font-weight: 500;
}
.aui-text__body-m {
  font-size: 15px;
  color: var(--aui-color-text-primary);
  line-height: 1rem;
  font-weight: 400;
}
.aui-text__body-s {
  font-size: 13px;
  color: var(--aui-color-text-primary);
  line-height: 1rem;
  font-weight: 300;
}
.aui-text__body-xs {
  font-size: 11px;
  color: var(--aui-color-text-secondary);
  line-height: 1rem;
  font-weight: 300;
}
.aui-link__primary {
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: calc(var(--aui-space) / 2);
  width: fit-content;
  color: var(--aui-color-base-interface);
  transition: all var(--aui-transition-speed) var(--aui-transition-smooth);
}
.aui-link__primary:active {
  color: var(--aui-color-base-interface-hover);
}
.aui-link__primary app-svg._stroke svg {
  fill: none;
  stroke: var(--aui-color-base-interface);
  transition: all var(--aui-transition-speed) var(--aui-transition-smooth);
}
.aui-link__primary app-svg._stroke svg:active {
  fill: none;
  stroke: var(--aui-color-base-interface-hover);
}
.aui-link__primary._disabled {
  opacity: 0.5;
  cursor: no-drop;
}
.aui-link__primary._disabled:active {
  color: var(--aui-color-base-interface);
}
.aui-link__primary._disabled svg:active,
.aui-link__primary._disabled tui-svg:active {
  fill: var(--aui-color-base-interface);
}
.aui-link__primary._disabled app-svg._stroke svg:active {
  fill: none;
  stroke: var(--aui-color-base-interface);
}
.aui-link__primary._exit {
  color: var(--aui-color-text-negative);
}
.aui-link__primary._exit:active {
  color: var(--aui-color-text-negative-hover);
}
.aui-link__primary._exit svg,
.aui-link__primary._exit tui-svg {
  fill: var(--aui-color-text-negative);
}
.aui-link__primary._exit svg:active,
.aui-link__primary._exit tui-svg:active {
  fill: var(--aui-color-text-negative-hover);
}
.aui-link__primary._exit app-svg._stroke svg {
  fill: none;
  stroke: var(--aui-color-text-negative);
  transition: all var(--aui-transition-speed) var(--aui-transition-smooth);
}
.aui-link__primary._exit app-svg._stroke svg:active {
  fill: none;
  stroke: var(--aui-color-text-negative-hover);
}
.aui-link__secondary {
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: calc(var(--aui-space) / 2);
  width: fit-content;
  color: var(--aui-color-base-interface);
  transition: all var(--aui-transition-speed) var(--aui-transition-smooth);
}
.aui-link__secondary:active {
  color: var(--aui-color-base-interface-hover);
}
.aui-link__secondary svg,
.aui-link__secondary tui-svg {
  fill: var(--aui-color-base-interface);
  transition: all var(--aui-transition-speed) var(--aui-transition-smooth);
}
.aui-link__secondary svg:active,
.aui-link__secondary tui-svg:active {
  fill: var(--aui-color-base-interface-hover);
}
.aui-link__secondary app-svg._stroke svg {
  fill: none;
  stroke: var(--aui-color-base-interface);
  transition: all var(--aui-transition-speed) var(--aui-transition-smooth);
}
.aui-link__secondary app-svg._stroke svg:active {
  fill: none;
  stroke: var(--aui-color-base-interface-hover);
}
.aui-link__secondary._disabled {
  opacity: 0.5;
  cursor: no-drop;
}
.aui-link__secondary._disabled:active {
  color: var(--aui-color-base-interface);
}
.aui-link__secondary._disabled svg:active,
.aui-link__secondary._disabled tui-svg:active {
  fill: var(--aui-color-base-interface);
}
.aui-link__secondary._disabled app-svg._stroke svg:active {
  fill: none;
  stroke: var(--aui-color-base-interface);
}
.aui-link__secondary._exit {
  color: var(--aui-color-text-negative);
}
.aui-link__secondary._exit:active {
  color: var(--aui-color-text-negative-hover);
}
.aui-link__secondary._exit svg,
.aui-link__secondary._exit tui-svg {
  fill: var(--aui-color-text-negative);
}
.aui-link__secondary._exit svg:active,
.aui-link__secondary._exit tui-svg:active {
  fill: var(--aui-color-text-negative-hover);
}
.aui-dropdown {
  display: flex;
  flex-direction: column;
  padding: var(--aui-space);
  border: 1px solid var(--aui-color-secondary);
  background-color: var(--aui-color-base-secondary);
  border-radius: var(--aui-space);
}
.aui-dropdown__item {
  padding: calc(var(--aui-space) / 2) var(--aui-space);
  width: auto;
  transition: all var(--aui-transition-speed) var(--aui-transition-smooth);
  border-radius: var(--aui-space);
}
.aui-dropdown__item._active {
  background-color: var(--aui-color-primary-active);
}
.aui-dropdown__item:hover {
  background-color: var(--aui-color-primary);
}
.aui-dropdown__item:active {
  background-color: var(--aui-color-primary-active);
}
.aui-dropdown__item._disabled:hover {
  background-color: transparent;
}
.aui-dropdown__item._disabled:active {
  background-color: transparent;
}
.aui-skin {
  display: flex;
  flex-direction: column;
  padding: 0 var(--aui-space);
  gap: calc(var(--aui-space) * 2);
  width: auto;
  height: 100%;
}
@media screen and (min-width: 48em) {
  .aui-skin {
    padding: 0 calc(var(--aui-space) * 15);
  }
}
@media screen and (min-width: 64em) {
  .aui-skin {
    padding: 0 calc(var(--aui-space) * 20);
  }
}
@media screen and (min-width: 80em) {
  .aui-skin {
    padding: 0 calc(var(--aui-space) * 30);
  }
}
@media screen and (min-width: 1920px) {
  .aui-skin {
    padding: 0 calc(var(--aui-space) * 40);
  }
}
.aui-button__primary {
  display: flex;
  align-items: center;
  gap: calc(var(--aui-space) / 2);
  padding: var(--aui-space) calc(var(--aui-space) * 6);
  width: fit-content;
  outline: 1px solid var(--aui-color-secondary);
  background-color: var(--aui-color-primary);
  color: var(--aui-color-base-interface);
  border-radius: var(--aui-space);
  transition: all var(--aui-transition-speed) var(--aui-transition-smooth);
}
.aui-button__primary:hover {
  background-color: var(--aui-color-primary-hover);
}
.aui-button__primary:active {
  background-color: var(--aui-color-primary-active);
  color: var(--aui-color-base-interface-hover);
}
.aui-button__primary:disabled:hover {
  background-color: var(--aui-color-primary);
}
.aui-button__primary:disabled:active {
  background-color: var(--aui-color-primary);
  color: var(--aui-color-base-interface);
}
.aui-button__primary svg,
.aui-button__primary tui-svg {
  fill: var(--aui-color-base-interface);
  transition: all var(--aui-transition-speed) var(--aui-transition-smooth);
}
.aui-button__primary svg:active,
.aui-button__primary tui-svg:active {
  fill: var(--aui-color-base-interface-hover);
}
.aui-button__primary._error {
  background-color: var(--aui-color-status-error-bg);
  color: var(--aui-color-status-error-fill);
}
.aui-button__primary._error:disabled {
  opacity: 1;
}
.aui-button__primary._error:disabled:hover {
  background-color: var(--aui-color-status-error-bg);
  color: var(--aui-color-status-error-fill);
}
.aui-button__primary._error:disabled:active {
  background-color: var(--aui-color-status-error-bg);
  color: var(--aui-color-status-error-fill);
}
.aui-button__primary._success {
  background-color: var(--aui-color-status-success-bg);
  color: var(--aui-color-status-success-fill);
}
.aui-button__primary._success:disabled {
  opacity: 1;
}
.aui-button__primary._success:disabled:hover {
  background-color: var(--aui-color-status-success-bg);
  color: var(--aui-color-status-success-fill);
}
.aui-button__primary._success:disabled:active {
  background-color: var(--aui-color-status-success-bg);
  color: var(--aui-color-status-success-fill);
}
.aui-button__secondary {
  display: flex;
  align-items: center;
  gap: calc(var(--aui-space) / 2);
  padding: var(--aui-space) calc(var(--aui-space) * 6);
  width: fit-content;
  outline: 1px solid var(--aui-color-secondary);
  background-color: var(--aui-color-base-secondary);
  color: var(--aui-color-base-interface);
  border-radius: var(--aui-space);
  transition: all var(--aui-transition-speed) var(--aui-transition-smooth);
}
.aui-button__secondary:active {
  background-color: var(--aui-color-base-primary);
  color: var(--aui-color-base-interface-hover);
}
.aui-button__secondary._active {
  background-color: var(--aui-color-base-primary);
  color: var(--aui-color-base-interface-hover);
}
.aui-button__secondary:disabled:active {
  background-color: var(--aui-color-base-secondary);
  color: var(--aui-color-base-interface);
}
.aui-button__secondary svg,
.aui-button__secondary tui-svg {
  fill: var(--aui-color-base-interface);
  transition: all var(--aui-transition-speed) var(--aui-transition-smooth);
}
.aui-button__secondary svg:active,
.aui-button__secondary tui-svg:active {
  fill: var(--aui-color-base-interface-hover);
}
.aui-button__helper {
  display: flex;
  align-items: center;
  gap: calc(var(--aui-space) / 2);
  padding: var(--aui-space) calc(var(--aui-space) * 6);
  width: fit-content;
  outline: 1px solid var(--aui-color-base-interface);
  background-color: transparent;
  color: var(--aui-color-base-interface);
  border-radius: var(--aui-space);
  transition: all var(--aui-transition-speed) var(--aui-transition-smooth);
}
.aui-button__helper:active {
  outline: 1px solid var(--aui-color-base-interface-hover);
  background-color: var(--aui-color-secondary);
  color: var(--aui-color-base-interface-hover);
}
.aui-button__helper._active {
  outline: 1px solid var(--aui-color-base-interface-hover);
  background-color: var(--aui-color-secondary);
  color: var(--aui-color-base-interface-hover);
}
.aui-button__helper:disabled:active {
  outline: 1px solid var(--aui-color-base-interface);
  background-color: transparent;
  color: var(--aui-color-base-interface);
}
.aui-button__helper svg,
.aui-button__helper tui-svg {
  fill: var(--aui-color-base-interface);
  transition: all var(--aui-transition-speed) var(--aui-transition-smooth);
}
.aui-button__helper svg:active,
.aui-button__helper tui-svg:active {
  fill: var(--aui-color-base-interface-hover);
}
.aui-input [tuiWrapper][data-appearance='textfield'] {
  background-color: var(--aui-color-base-secondary);
}
.aui-input [tuiWrapper][data-appearance='textfield'][data-mode='onDark'] {
  background-color: var(--aui-color-base-secondary);
}
.aui-input:hover [tuiWrapper][data-appearance='textfield'][data-mode='onDark'] {
  background-color: var(--aui-color-base-secondary);
}
.aui-input [tuiWrapper][data-appearance='textfield'][data-mode='onDark']._focused {
  background-color: var(--aui-color-base-primary);
}
.aui-input input[_nghost-ng-c3600615812] {
  font-size: 15px;
  color: var(--aui-color-text-primary);
  line-height: 1rem;
  font-weight: 400;
}
.aui-input__transparent input[_nghost-ng-c3600615812] {
  font-size: 15px;
  color: var(--aui-color-text-primary);
  line-height: 1rem;
  font-weight: 400;
}
.aui-input__transparent [tuiWrapper][data-appearance='textfield'] {
  background-color: var(--aui-color-base-secondary);
}
.aui-input__transparent [tuiWrapper][data-appearance='textfield'][data-mode='onDark'] {
  background-color: transparent !important;
}
.aui-input__transparent [tuiWrapper][data-appearance='textfield'][data-mode='onDark']::after {
  color: transparent;
}
.aui-input__transparent [tuiWrapper][data-appearance='textfield'][data-mode='onDark']:hover {
  background-color: transparent;
}
.aui-input__transparent [tuiWrapper][data-appearance='textfield'][data-mode='onDark']:hover::after {
  color: transparent;
}
.aui-input__transparent [tuiWrapper][data-appearance='textfield'][data-mode='onDark']:active {
  background-color: transparent;
}
.aui-input__transparent [tuiWrapper][data-appearance='textfield'][data-mode='onDark']:active::after {
  color: transparent;
}
.aui-input__transparent [data-size=s][_nghost-ng-c2724824487] .t-content[_ngcontent-ng-c2724824487] {
  padding: 0 calc(var(--aui-space) / 2) 0 0;
}
.aui-margin__top > * {
  margin-top: calc(var(--aui-space) * 4);
}
.aui-margin__right > * {
  margin-right: calc(var(--aui-space) * 4);
}
.aui-carousel__buttons tui-carousel {
  height: inherit;
}
.aui-carousel__buttons tui-carousel div.t-scroller {
  height: inherit;
}
.aui-carousel__buttons tui-carousel div.t-items {
  height: -webkit-fill-available;
  height: -moz-available;
}
.item__discount {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--aui-space);
  padding: var(--aui-space);
  border-radius: var(--aui-space);
}
.item__discount._first-level {
  background-color: var(--aui-color-status-neutral-bg);
  color: var(--aui-color-status-neutral-fill);
}
.item__discount._second-level {
  background-color: var(--aui-color-status-warning-bg);
  color: var(--aui-color-status-warning-fill);
}
.item__discount._third-level {
  background-color: var(--aui-color-status-error-bg);
  color: var(--aui-color-status-error-fill);
}
.buttons__counter {
  display: flex;
  align-items: center;
  gap: calc(var(--aui-space) / 2);
}
.buttons__counter button {
  padding: 0;
  outline: none;
}
.buttons__item:first-child {
  font-weight: 700;
  font-family: 'Halvar Breit';
  padding: calc(var(--aui-space) / 2) calc(var(--aui-space) * 2);
}
.buttons__item:last-child {
  padding: 0 calc(var(--aui-space) / 2);
  height: 26px;
}
.buttons__go-to-cart input {
  cursor: pointer;
}
.aui-loader {
  opacity: 0;
}
.aui-loader__app-item {
  background-color: var(--aui-color-base-secondary);
}
.aui-loader__app-item p {
  opacity: 0;
}
.aui-loader__app-item hr {
  opacity: 0;
}
.aui-loader__app-item button {
  opacity: 0;
}
.aui-table {
  width: 100%;
}
.aui-table th {
  background-color: var(--aui-color-base-secondary);
  color: var(--aui-color-text-primary);
  border-color: var(--aui-color-secondary);
  vertical-align: middle;
}
.aui-table td {
  background-color: var(--aui-color-base-secondary);
  color: var(--aui-color-text-secondary);
  border-color: var(--aui-color-secondary);
  vertical-align: middle;
}
* {
  font-family: 'Graphik LCG', sans-serif;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
small {
  cursor: default;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  cursor: pointer;
  text-decoration: none;
}
button {
  border: none;
  cursor: pointer;
  background-color: transparent;
}
::selection {
  background-color: var(--aui-color-secondary);
}
*:disabled {
  opacity: 0.5;
  cursor: no-drop;
}
@keyframes noise {
  0% {
    background-image: url(./assets/animations/noise/noise-1.svg);
  }
  25% {
    background-image: url(./assets/animations/noise/noise-3.svg);
  }
  50% {
    background-image: url(./assets/animations/noise/noise-2.svg);
  }
  75% {
    background-image: url(./assets/animations/noise/noise-3.svg);
  }
  100% {
    background-image: url(./assets/animations/noise/noise-4.svg);
  }
}
:root {
  --aui-animation-noise: noise 1s var(--aui-transition-smooth) infinite alternate;
}
