@font-face {
  font-family: 'Graphik LCG';
  src: url('./../../assets/fonts/GraphikLCG/GraphikLCG-MediumItalic.eot');
  src: local('Graphik LCG Medium Italic'), local('GraphikLCG-MediumItalic'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-MediumItalic.woff2') format('woff2'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-MediumItalic.woff') format('woff'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('./../../assets/fonts/GraphikLCG/GraphikLCG-SuperItalic.eot');
  src: local('Graphik LCG Super Italic'), local('GraphikLCG-SuperItalic'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-SuperItalic.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-SuperItalic.woff2') format('woff2'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-SuperItalic.woff') format('woff'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-SuperItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('./../../assets/fonts/GraphikLCG/GraphikLCG-SemiboldItalic.eot');
  src: local('Graphik LCG Semibold Italic'), local('GraphikLCG-SemiboldItalic'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-SemiboldItalic.woff2') format('woff2'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-SemiboldItalic.woff') format('woff'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('./../../assets/fonts/GraphikLCG/GraphikLCG-Black.eot');
  src: local('Graphik LCG Black'), local('GraphikLCG-Black'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Black.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Black.woff2') format('woff2'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Black.woff') format('woff'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('./../../assets/fonts/GraphikLCG/GraphikLCG-Super.eot');
  src: local('Graphik LCG Super'), local('GraphikLCG-Super'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Super.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Super.woff2') format('woff2'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Super.woff') format('woff'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Super.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('./../../assets/fonts/GraphikLCG/GraphikLCG-Thin.eot');
  src: local('Graphik LCG Thin'), local('GraphikLCG-Thin'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Thin.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Thin.woff2') format('woff2'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Thin.woff') format('woff'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('./../../assets/fonts/GraphikLCG/GraphikLCG-ExtralightItalic.eot');
  src: local('Graphik LCG Extralight Italic'), local('GraphikLCG-ExtralightItalic'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-ExtralightItalic.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-ExtralightItalic.woff2') format('woff2'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-ExtralightItalic.woff') format('woff'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-ExtralightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('./../../assets/fonts/GraphikLCG/GraphikLCG-Regular.eot');
  src: local('Graphik LCG Regular'), local('GraphikLCG-Regular'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Regular.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Regular.woff2') format('woff2'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Regular.woff') format('woff'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('./../../assets/fonts/GraphikLCG/GraphikLCG-BoldItalic.eot');
  src: local('Graphik LCG Bold Italic'), local('GraphikLCG-BoldItalic'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-BoldItalic.woff2') format('woff2'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-BoldItalic.woff') format('woff'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('./../../assets/fonts/GraphikLCG/GraphikLCG-LightItalic.eot');
  src: local('Graphik LCG Light Italic'), local('GraphikLCG-LightItalic'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-LightItalic.woff2') format('woff2'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-LightItalic.woff') format('woff'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('./../../assets/fonts/GraphikLCG/GraphikLCG-BlackItalic.eot');
  src: local('Graphik LCG Black Italic'), local('GraphikLCG-BlackItalic'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-BlackItalic.woff2') format('woff2'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-BlackItalic.woff') format('woff'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('./../../assets/fonts/GraphikLCG/GraphikLCG-Medium.eot');
  src: local('Graphik LCG Medium'), local('GraphikLCG-Medium'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Medium.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Medium.woff2') format('woff2'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Medium.woff') format('woff'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('./../../assets/fonts/GraphikLCG/GraphikLCG-Light.eot');
  src: local('Graphik LCG Light'), local('GraphikLCG-Light'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Light.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Light.woff2') format('woff2'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Light.woff') format('woff'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('./../../assets/fonts/GraphikLCG/GraphikLCG-Semibold.eot');
  src: local('Graphik LCG Semibold'), local('GraphikLCG-Semibold'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Semibold.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Semibold.woff2') format('woff2'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Semibold.woff') format('woff'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('./../../assets/fonts/GraphikLCG/GraphikLCG-Bold.eot');
  src: local('Graphik LCG Bold'), local('GraphikLCG-Bold'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Bold.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Bold.woff2') format('woff2'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Bold.woff') format('woff'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('./../../assets/fonts/GraphikLCG/GraphikLCG-Extralight.eot');
  src: local('Graphik LCG Extralight'), local('GraphikLCG-Extralight'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Extralight.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Extralight.woff2') format('woff2'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Extralight.woff') format('woff'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-Extralight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('./../../assets/fonts/GraphikLCG/GraphikLCG-ThinItalic.eot');
  src: local('Graphik LCG Thin Italic'), local('GraphikLCG-ThinItalic'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-ThinItalic.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-ThinItalic.woff2') format('woff2'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-ThinItalic.woff') format('woff'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('./../../assets/fonts/GraphikLCG/GraphikLCG-RegularItalic.eot');
  src: local('Graphik LCG Regular Italic'), local('GraphikLCG-RegularItalic'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-RegularItalic.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-RegularItalic.woff2') format('woff2'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-RegularItalic.woff') format('woff'),
      url('./../../assets/fonts/GraphikLCG/GraphikLCG-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Druk Wide Cyr';
  src: url('./../../assets/fonts/DrukCyrillicCollection/DrukWideCyrBold.otf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Druk Wide Cyr';
  src: url('./../../assets/fonts/DrukCyrillicCollection/DrukWideCyrBoldItalic.otf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Druk Wide Cyr';
  src: url('./../../assets/fonts/DrukCyrillicCollection/DrukWideCyrHeavy.otf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Druk Wide Cyr';
  src: url('./../../assets/fonts/DrukCyrillicCollection/DrukWideCyrHeavyItalic.otf');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Druk Wide Cyr';
  src: url('./../../assets/fonts/DrukCyrillicCollection/DrukWideCyrMedium.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Druk Wide Cyr';
  src: url('./../../assets/fonts/DrukCyrillicCollection/DrukWideCyrMediumItalic.otf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Druk Wide Cyr';
  src: url('./../../assets/fonts/DrukCyrillicCollection/DrukWideCyrSuper.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Druk Wide Cyr';
  src: url('./../../assets/fonts/DrukCyrillicCollection/DrukWideCyrSuperItalic.otf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-BlkSlanted.eot');
  src: local('Halvar Breitschrift Black Slanted'), local('HalvarBreit-BlkSlanted'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-BlkSlanted.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-BlkSlanted.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-BlkSlanted.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-BlkSlanted.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-Blk.eot');
  src: local('Halvar Breitschrift Black'), local('HalvarBreit-Blk'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Blk.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Blk.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Blk.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Blk.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-BlkSuSlanted.eot');
  src: local('Halvar Breitschrift Black SuperSlanted'), local('HalvarBreit-BlkSuSlanted'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-BlkSuSlanted.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-BlkSuSlanted.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-BlkSuSlanted.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-BlkSuSlanted.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-Lt.eot');
  src: local('Halvar Breitschrift Light'), local('HalvarBreit-Lt'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Lt.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Lt.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Lt.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Lt.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-HlSuSlanted.eot');
  src: local('Halvar Breitschrift Hairline SuperSlanted'), local('HalvarBreit-HlSuSlanted'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-HlSuSlanted.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-HlSuSlanted.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-HlSuSlanted.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-HlSuSlanted.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-Md.eot');
  src: local('Halvar Breitschrift Medium'), local('HalvarBreit-Md'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Md.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Md.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Md.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Md.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-XThSuSlanted.eot');
  src: local('Halvar Breitschrift ExtraThin SuperSlanted'), local('HalvarBreit-XThSuSlanted'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-XThSuSlanted.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-XThSuSlanted.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-XThSuSlanted.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-XThSuSlanted.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-Bd.eot');
  src: local('Halvar Breitschrift Bold'), local('HalvarBreit-Bd'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Bd.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Bd.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Bd.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Bd.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-MdSuSlanted.eot');
  src: local('Halvar Breitschrift Medium SuperSlanted'), local('HalvarBreit-MdSuSlanted'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-MdSuSlanted.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-MdSuSlanted.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-MdSuSlanted.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-MdSuSlanted.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-HlSlanted.eot');
  src: local('Halvar Breitschrift Hairline Slanted'), local('HalvarBreit-HlSlanted'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-HlSlanted.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-HlSlanted.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-HlSlanted.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-HlSlanted.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-RgSlanted.eot');
  src: local('Halvar Breitschrift Regular Slanted'), local('HalvarBreit-RgSlanted'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-RgSlanted.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-RgSlanted.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-RgSlanted.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-RgSlanted.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-XBd.eot');
  src: local('Halvar Breitschrift ExtraBold'), local('HalvarBreit-XBd'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-XBd.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-XBd.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-XBd.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-XBd.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-XThSlanted.eot');
  src: local('Halvar Breitschrift ExtraThin Slanted'), local('HalvarBreit-XThSlanted'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-XThSlanted.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-XThSlanted.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-XThSlanted.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-XThSlanted.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-ThSuSlanted.eot');
  src: local('Halvar Breitschrift Thin SuperSlanted'), local('HalvarBreit-ThSuSlanted'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-ThSuSlanted.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-ThSuSlanted.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-ThSuSlanted.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-ThSuSlanted.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-XBdSuSlanted.eot');
  src: local('Halvar Breitschrift ExtraBold SuperSlanted'), local('HalvarBreit-XBdSuSlanted'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-XBdSuSlanted.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-XBdSuSlanted.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-XBdSuSlanted.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-XBdSuSlanted.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-RgSuSlanted.eot');
  src: local('Halvar Breitschrift Regular SuperSlanted'), local('HalvarBreit-RgSuSlanted'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-RgSuSlanted.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-RgSuSlanted.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-RgSuSlanted.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-RgSuSlanted.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-XBdSlanted.eot');
  src: local('Halvar Breitschrift ExtraBold Slanted'), local('HalvarBreit-XBdSlanted'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-XBdSlanted.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-XBdSlanted.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-XBdSlanted.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-XBdSlanted.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-LtSuSlanted.eot');
  src: local('Halvar Breitschrift Light SuperSlanted'), local('HalvarBreit-LtSuSlanted'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-LtSuSlanted.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-LtSuSlanted.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-LtSuSlanted.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-LtSuSlanted.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-Th.eot');
  src: local('Halvar Breitschrift Thin'), local('HalvarBreit-Th'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Th.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Th.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Th.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Th.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-XTh.eot');
  src: local('Halvar Breitschrift ExtraThin'), local('HalvarBreit-XTh'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-XTh.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-XTh.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-XTh.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-XTh.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-BdSuSlanted.eot');
  src: local('Halvar Breitschrift Bold SuperSlanted'), local('HalvarBreit-BdSuSlanted'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-BdSuSlanted.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-BdSuSlanted.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-BdSuSlanted.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-BdSuSlanted.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-ThSlanted.eot');
  src: local('Halvar Breitschrift Thin Slanted'), local('HalvarBreit-ThSlanted'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-ThSlanted.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-ThSlanted.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-ThSlanted.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-ThSlanted.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-LtSlanted.eot');
  src: local('Halvar Breitschrift Light Slanted'), local('HalvarBreit-LtSlanted'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-LtSlanted.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-LtSlanted.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-LtSlanted.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-LtSlanted.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-Hl.eot');
  src: local('Halvar Breitschrift Hairline'), local('HalvarBreit-Hl'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Hl.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Hl.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Hl.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Hl.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-MdSlanted.eot');
  src: local('Halvar Breitschrift Medium Slanted'), local('HalvarBreit-MdSlanted'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-MdSlanted.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-MdSlanted.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-MdSlanted.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-MdSlanted.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-BdSlanted.eot');
  src: local('Halvar Breitschrift Bold Slanted'), local('HalvarBreit-BdSlanted'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-BdSlanted.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-BdSlanted.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-BdSlanted.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-BdSlanted.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-Hl_1.eot');
  src: local('Halvar Breitschrift Hairline'), local('HalvarBreit-Hl'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Hl_1.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Hl_1.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Hl_1.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Hl_1.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Halvar Breit';
  src: url('./../../assets/fonts/HalvarBreit/HalvarBreit-Rg.eot');
  src: local('Halvar Breitschrift Regular'), local('HalvarBreit-Rg'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Rg.eot?#iefix') format('embedded-opentype'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Rg.woff2') format('woff2'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Rg.woff') format('woff'),
      url('./../../assets/fonts/HalvarBreit/HalvarBreit-Rg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}