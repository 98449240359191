#aui-font() {
  & {
    font-size: 15px;
    color: var(--aui-color-text-primary);
    line-height: 1rem;
  }

  .h0 {
    font-size: 64px;
    color: var(--aui-color-text-primary);
    line-height: 2rem;
  }

  .h1 {
    font-size: 50px;
    color: var(--aui-color-text-primary);
    line-height: 2rem;
  }

  .h2 {
    font-size: 44px;
    color: var(--aui-color-text-primary);
    line-height: 2rem;
  }

  .h3 {
    font-size: 36px;
    color: var(--aui-color-text-primary);
    line-height: 2rem;
  }

  .h4 {
    font-size: 28px;
    color: var(--aui-color-text-primary);
    line-height: 2rem;
  }

  .h5 {
    font-size: 24px;
    color: var(--aui-color-text-primary);
    line-height: 2rem;
  }
  
  .h6 {
    font-size: 20px;
    color: var(--aui-color-text-primary);
    line-height: 2rem;
  }

  .body-xl {
    font-size: 19px;
    color: var(--aui-color-text-primary);
    line-height: 1rem;
  }

  .body-l {
    font-size: 17px;
    color: var(--aui-color-text-secondary);
    line-height: 1rem;
  }

  .body-m {
    font-size: 15px;
    color: var(--aui-color-text-primary);
    line-height: 1rem;
  }

  .body-s {
    font-size: 13px;
    color: var(--aui-color-text-primary);
    line-height: 1rem;
  }

  .body-xs {
    font-size: 11px;
    color: var(--aui-color-text-secondary);
    line-height: 1rem;
  }
}

#aui-font-family() {
  & {
    font-family: 'Graphik LCG';
  }

  .default {
    font-family: 'Graphik LCG';
  }

  .title {
    font-family: 'Halvar Breit';
  }

  .logo {
    font-family: 'Druk Wide Cyr';
  }
}

#aui-font-weight() {
  & {
    font-weight: 400;
  }

  .light {
    font-weight: 300;
  }

  .regular {
    font-weight: 400;
  }

  .medium {
    font-weight: 500;
  }

  .semibold {
    font-weight: 600;
  }

  .bold {
    font-weight: 700;
  }

  .extrabold {
    font-weight: 800;
  }
}