:root {
  --aui-space: 10px;

  --aui-transition-speed: .25s;
  --aui-transition-smooth: cubic-bezier(0.445, 0.050, 0.550, 0.950);

  --aui-color-primary: #D03100;
  --aui-color-primary-hover: #9e0011;
  --aui-color-primary-active: #e45e01;
  --aui-color-primary-light: #f4a261;
  --aui-color-primary-light: #f4a261;
  --aui-color-primary-light-hover: #e9c46a;
  --aui-color-primary-light-active: #e76f51;
  --aui-color-secondary: #FFFFFF20;
  --aui-color-secondary-hover: #FFFFFF30;
  --aui-color-secondary-active: #FFFFFF40;
  --aui-color-selection: #161E2210;

  --aui-color-text-primary: #FCFCFD;
  --aui-color-text-secondary: #F3F5F790;
  --aui-color-text-helper: #E0E1E070;
  --aui-color-text-link: #A3CEF1;
  --aui-color-text-link-hover: #70B3EA;
  --aui-color-text-positive: #CDF1A2;
  --aui-color-text-positive-hover: #A5E657;
  --aui-color-text-negative: #F8CFC9;
  --aui-color-text-negative-hover: #ED8474;
  --aui-color-text-primary-night: #0E0E16;
  --aui-color-text-secondary-night: #0E111590;
  --aui-color-text-helper-night: #10101070;
  --aui-color-text-link-night: #1A6CAF;
  --aui-color-text-link-night-hover: #175D97;
  --aui-color-text-positive-night: #6CAF1A;
  --aui-color-text-positive-night-hover: #558A15;
  --aui-color-text-negative-night: #C7311A;
  --aui-color-text-negative-night-hover: #9C2614;

  --aui-color-status-error-fill: #F8CFC9;
  --aui-color-status-error-bg: #DC565850;
  --aui-color-status-error-bg-hover: #DC565870;
  --aui-color-status-success-fill: #C9F8EF;
  --aui-color-status-success-bg: #1AC7A750;
  --aui-color-status-success-bg-hover: #1AC7A770;
  --aui-color-status-warning-fill: #F8EFC9;
  --aui-color-status-warning-bg: #A67D0050;
  --aui-color-status-warning-bg-hover: #A67D0070;
  --aui-color-status-info-fill: #C9E1F8;
  --aui-color-status-info-bg: #45607A50;
  --aui-color-status-info-bg-hover: #45607A70;
  --aui-color-status-neutral-fill: #CBCCCF;
  --aui-color-status-neutral-bg: #999CA050;
  --aui-color-status-neutral-bg-hover: #999CA070;
  --aui-color-status-error-fill-night: #DC5658;
  --aui-color-status-error-bg-night: #F8CFC950;
  --aui-color-status-error-bg-night-hover: #F8CFC970;
  --aui-color-status-success-fill-night: #1AC7A7;
  --aui-color-status-success-bg-night: #C9F8EF50;
  --aui-color-status-success-bg-night-hover: #C9F8EF70;
  --aui-color-status-warning-fill-night: #FFC20B;
  --aui-color-status-warning-bg-night: #F8EFC950;
  --aui-color-status-warning-bg-night-hover: #F8EFC970;
  --aui-color-status-info-fill-night: #45607A;
  --aui-color-status-info-bg-night: #C9E1F850;
  --aui-color-status-info-bg-night-hover: #C9E1F870;
  --aui-color-status-neutral-fill-night: #999CA0;
  --aui-color-status-neutral-bg-night: #CBCCCF50;
  --aui-color-status-neutral-bg-night-hover: #CBCCCF70;

  --aui-color-base-primary: #0D0D0D;
  --aui-color-base-secondary: #141414;
  --aui-color-base-helper: #868A88;
  --aui-color-base-helper-hover: #8F979B;
  --aui-color-base-interface: #C7CBCD;
  --aui-color-base-interface-hover: #E5EBEA;
  --aui-color-base-primary-invert: #F2F4F3;
  --aui-color-base-secondary-invert: #DCE3DB;
  --aui-color-base-helper-invert: #F8F9F9;
  --aui-color-base-interface-invert: #FFFFFF;
}