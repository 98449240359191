@keyframes noise {
  0% {
    background-image: url(./../../assets/animations/noise/noise-1.svg);
  }
  25% {
    background-image: url(./../../assets/animations/noise/noise-3.svg);
  }
  50% {
    background-image: url(./../../assets/animations/noise/noise-2.svg);
  }
  75% {
    background-image: url(./../../assets/animations/noise/noise-3.svg);
  }
  100% {
    background-image: url(./../../assets/animations/noise/noise-4.svg);
  }
}

:root {
  --aui-animation-noise: noise 1s var(--aui-transition-smooth) infinite alternate;
}