@import "./../atlant-ui/atlant-ui-namespaces.less";

body[data-tui-theme].r-tui-body {
  background-color: var(--aui-color-base-primary);
}

tui-dropdown[_nghost-ng-c2374896908] {
  border: 1px solid var(--aui-color-secondary);

  background: none;
}

app-root {
  display: block;

  height: 100%;
}

.t-root-content {
  display: table;
  border-spacing: 0;
  border-collapse: separate;

  width: 100%;
}

fieldset.t-item[_ngcontent-ng-c121344037] {
  align-items: center;
  padding: 0;
}

div[tuiWrapper][data-appearance='textfield'][data-state='readonly'][data-state='readonly']:after {
  color: var(--aui-color-secondary);
}

div.t-thumb[_ngcontent-ng-c2790313665] {
  color: white;
}

tui-select-option {
  .t-content {
    cursor: pointer;
  }
}

tui-dropdown[_nghost-ng-c2374896908] {
  background-color: var(--aui-color-base-secondary);
}

tui-dropdown[data-mode=onDark][_nghost-ng-c2374896908] {
  border: 1px solid var(--aui-color-secondary);

  background-color: var(--aui-color-base-secondary);

  button:hover {
    background-color: var(--aui-color-base-primary);
  }
}

[_nghost-ng-c819886985] div.t-wrapper[_ngcontent-ng-c819886985]:after {
  border-color: var(--aui-color-secondary);
}

[data-mode=onDark][_nghost-ng-c819886985] div.t-wrapper[_ngcontent-ng-c819886985]:after {
  border-color: var(--aui-color-secondary);
}

button.t-header[_ngcontent-ng-c819886985] {
  .t-title {
    cursor: pointer;
  }
}

[_nghost-ng-c819886985] button.t-header[_ngcontent-ng-c819886985] {
  border-color: var(--aui-color-secondary);
  background-color: var(--aui-color-base-secondary);
}

[data-mode=onDark][_nghost-ng-c819886985] button.t-header[_ngcontent-ng-c819886985] {
  border-color: var(--aui-color-secondary);
  background-color: var(--aui-color-base-secondary);
}

.t-wrapper[_ngcontent-ng-c819886985]:hover:not([data-mode]) > button.t-header_hoverable[_ngcontent-ng-c819886985] {
  background-color: var(--aui-color-base-primary);
}

input.t-input[_ngcontent-ng-c2294440466] {
  color: var(--aui-color-text-primary);
}

:root {
  --tui-primary: #f4a261;
  --tui-primary-hover: #e9c46a;
  --tui-primary-active: #e76f51;
}

span.t-content[_ngcontent-ng-c2966975625] {
  cursor: pointer;
}

div[tuiWrapper][data-appearance='secondary'] {
  background-color: var(--aui-color-base-secondary);
}

div[tuiWrapper][data-appearance='secondary'][data-mode='onDark'] {
  background-color: var(--aui-color-base-secondary);
}

div[tuiWrapper][data-appearance='secondary'][data-mode='onDark']:hover:not(._no-hover) {
  background-color: var(--aui-color-base-primary);
}

div[tuiWrapper][data-appearance='secondary'][data-mode='onDark']:active:not(._no-active) {
  background-color: var(--aui-color-primary);
}

[data-size=m][tuiIconButton][_nghost-ng-c3926154569] {
  .t-svg[_ngcontent-ng-c3944244699] {
    cursor: pointer;
  }
}

div.t-content[_ngcontent-ng-c1591437670] {
  width: auto;

  background: transparent;
}

div.t-buttons[_ngcontent-ng-c1591437670] {
  display: none;
}

tui-dialog[_nghost-ng-c1591437670]::after {
  box-shadow: none;
}

tui-dialog[data-size=l][_nghost-ng-c1591437670] {
  width: 70rem;
}

tui-root._mobile tui-dialog[data-size][_nghost-ng-c1591437670] {
  margin: auto 0;
}

[_nghost-ng-c1591437670]:not([data-size="fullscreen"]):not([data-size="page"]) button.t-close[_ngcontent-ng-c1591437670] {
  background-color: var(--aui-color-base-secondary);
  cursor: pointer;

  &:active {
    background-color: var(--aui-color-base-primary);
  }

  svg.t-svg[_ngcontent-ng-c3944244699] {
    cursor: pointer;
  }
}

[_nghost-ng-c1591437670]:not([data-size="fullscreen"]):not([data-size="page"]) button.t-close[_ngcontent-ng-c1591437670]:hover {
  background-color: var(--aui-color-base-primary);
}

tui-root._mobile [_nghost-ng-c1591437670] button.t-close[_ngcontent-ng-c1591437670] {
  color: var(--aui-color-text-primary);
}

tui-data-list[data-list-size=s]>div.t-empty {
  color: var(--aui-color-text-helper);
}

[data-size=m][_nghost-ng-c219241606] button.t-close[_ngcontent-ng-c219241606] {
  svg {
    cursor: pointer;
  }
}

[tuiWrapper][data-mode=onDark][_ngcontent-ng-c2294440466] label.t-placeholder[_ngcontent-ng-c2294440466] {
  display: flex;
  align-items: center;
  gap: var(--aui-space);

  span {
    #aui-font.body-xs();
  }
}

tui-badge[data-status=primary][_nghost-ng-c783107866] {
  background-color: var(--aui-color-primary);
}

tui-badge[data-status=default][_nghost-ng-c783107866] {
  border: 1px solid var(--aui-color-secondary);

  background-color: var(--aui-color-status-info-bg);
}

tui-hint {
  background-color: var(--aui-color-base-secondary) !important;
  color: var(--aui-color-text-primary) !important;
}

tui-alert {
  margin: 6rem 3rem 0 auto !important;
}

tui-badge[data-status=info][_nghost-ng-c783107866] {
  color: var(--aui-color-text-primary);
}

tui-loader {
  fieldset.t-content[_ngcontent-ng-c717223331] {
    display: flex;
    flex-direction: column;
    gap: var(--aui-space);
    margin-top: calc(var(--aui-space) * 2);
  }
}

tui-badge[data-status=success][_nghost-ng-c783107866] {
  color: var(--aui-color-text-primary);
}

tui-badge[data-status=error][_nghost-ng-c783107866] {
  color: var(--aui-color-text-primary);
}

.t-content[_ngcontent-ng-c3926154569] {
  color: var(--aui-color-text-primary);
}

div.t-row_weekday[_ngcontent-ng-c3738844098] {
  color: var(--aui-color-text-secondary);
}

span.t-content[_ngcontent-ng-c2877806562] {
  color: var(--aui-color-text-primary);
}

div.t-item[_ngcontent-ng-c3959805629] {
  color: var(--aui-color-text-primary);
}

tui-radio-block {
  [tuiWrapper][data-appearance='secondary'][data-mode='onDark'] {
    background-color: var(--aui-color-base-secondary);
  }

  [tuiWrapper][data-appearance='primary'] {
    background-color: var(--aui-color-primary);

    &:hover {
      background-color: var(--aui-color-primary) !important;
    }
  }
}

div.t-primitive-value[_ngcontent-ng-c317350843] {
  color: var(--aui-color-text-primary);
}

span.t-content[_ngcontent-ng-c2966975625] {
  color: var(--aui-color-base-interface);
}

div[tuiWrapper][data-appearance='textfield'][data-appearance='textfield']:after {
  color: var(--aui-color-secondary);
}

tui-arrow {
  svg {
    color: var(--aui-color-base-interface);
  }
}

tui-data-list[data-list-size="s"] [_nghost-ng-c1028746238] .t-checkmark[_ngcontent-ng-c1028746238] {
  svg {
    color: var(--aui-color-base-interface);
  }
}

div.t-empty {
  color: var(--aui-color-text-helper);
  font-size: 12px;
}